import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.layout = void 0;
var _core = _core2;

var isNumber = function isNumber(n) {
  return typeof n === "number" && !isNaN(n);
};

var getWidth = function getWidth(n, scale) {
  return (0, _core.get)(scale, n, !isNumber(n) || n > 1 ? n : n * 100 + "%");
};

var config = {
  width: {
    property: "width",
    scale: "sizes",
    transform: getWidth
  },
  height: {
    property: "height",
    scale: "sizes"
  },
  minWidth: {
    property: "minWidth",
    scale: "sizes"
  },
  minHeight: {
    property: "minHeight",
    scale: "sizes"
  },
  maxWidth: {
    property: "maxWidth",
    scale: "sizes"
  },
  maxHeight: {
    property: "maxHeight",
    scale: "sizes"
  },
  size: {
    properties: ["width", "height"],
    scale: "sizes"
  },
  overflow: true,
  overflowX: true,
  overflowY: true,
  display: true,
  verticalAlign: true
};
var layout = (0, _core.system)(config);
exports.layout = layout;
var _default = layout;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _layout = exports.layout;
export { _layout as layout };